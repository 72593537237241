<template>
  <div class="main">
    <div class="circle" v-if="pay_status">
      <Icon name="success" color="#fff" size="13vw" style="margin-top:7vw"/>
    </div>
    <div class="circle2" v-else>
      <Icon name="cross" color="#fff" size="13vw" style="margin-top:7vw"/>
    </div>
    <div class="text" v-if="pay_status">支付成功</div>
    <div class="text" v-else>支付失败</div>
  </div>
</template>
<script>
import { Icon } from 'vant';
export default {
  components: {
    Icon
  },
  data() {
    return {
      pay_status: true,
    }
  },
  mounted() {
    this.getStatus()
  },
  methods: {
    getStatus(){
      if (this.$route.query.hasOwnProperty("status")) {
        if (this.$route.query.status == "TRADE_SUCCESS") {
          this.pay_status = true
        } else {
          this.pay_status = false
        }
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100vh;
  align-items: center;
  padding-top: 70px;
  .circle{
    width: 26vw;
    height: 26vw;
    border-radius: 13vw;
    line-height: 26vw;
    text-align: center;
    background: #5fb878;
    margin: 0 auto;
  }
  .circle2{
    width: 26vw;
    height: 26vw;
    border-radius: 13vw;
    line-height: 26vw;
    text-align: center;
    background: #f56c6c;
    margin: 0 auto;
  }
  .text{
    margin-top: 15px;
    font-size: 16px;
    text-align: center;
  }
}
</style>